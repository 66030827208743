/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable no-undef */
/* eslint-disable import/extensions */
/* eslint-disable quotes */
import { commonServices } from '@/api/common-services'
import { REPORT_URL } from "@/api/constant"
import { booking } from '@/api/booking'
import "@/assets/js/telerikReportViewer-17.0.23.118.min.js"
import SidebarListReport from './SidebarListReport/SidebarListReport.vue'

export default {
  data() {
    return {
      listReportGroup: null,
      listReport: [],
      pToken: null,
      pLang: null,
      pTimeZone: null,
      pTimeZoneOffset: null,
      fileName: null,
      pUrlReport: null,
      pUrlCourse: null,
      pUrlSite: null,
      pHotelId: null,
      pUrlService: null,
      listFileName: null,
      pUrlMember: null,
      pUrlFolio: null,
      pRoleOfCashier: null,
      pUrlAr: null,
      pFrefix: null,
    }
  },
  components: {
    SidebarListReport,
  },
  created() {
    this.getRoleCashier()
    this.api_RCOM01()
    this.getParam()
    this.api_RCF00()
  },
  mounted() {
    this.queryTelerik()
  },
  methods: {
    Control() {
      this.Open()
    },
    Open() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-list-report')
    },
    queryTelerik() {
      $("#Ez-report").telerik_ReportViewer({
        serviceUrl: REPORT_URL,
        viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
        scaleMode: telerikReportViewer.ScaleModes.FIT_PAGE_WIDTH,
        scale: 1.0,
        sendEmail: { enabled: false },
        renderingEnd: function () {
          var datepickers = $("#Ez-report").find("[data-role='datepicker']")

          if (datepickers.length) {
            datepickers.each(function (index) {
              $(this).data("kendoDatePicker").setOptions({
                format: "dd/MM/yyyy",
              })
            })
          }
        },
        parameterEditors: [
          {
            match(parameter) {
              return Boolean(parameter.availableValues) && !parameter.multivalue
            },

            createEditor(placeholder, options) {
              $(placeholder).css('width', '100%')
              const dropDownElement = $(placeholder).html('<div style="width:100%"></div>')
              let parameter
              const valueChangedCallback = options.parameterChanged
              let dropDownList

              function onChange() {
                const val = dropDownList.value()
                valueChangedCallback(parameter, val)
                const paramBackup = {}
                self.parameterInReports.forEach(item => {
                  paramBackup[item.name] = item.value
                })
                self.parameterInReports = []
                self.fetchReport(this.LS_CurrentLanguageCode, paramBackup)
              }

              return {
                beginEdit(param) {
                  parameter = param
                  $(dropDownElement).kendoComboBox({
                    dataTextField: 'name',
                    dataValueField: 'value',
                    value: parameter.value,
                    optionLabel: 'All',
                    filter: 'contains',
                    autoRefresh: true,
                    dataSource: parameter.availableValues,
                    change: onChange,
                  })

                  dropDownList = $(dropDownElement).data('kendoComboBox')
                },
              }
            },
          },
        ],
      })
    },
    getParam() {
      const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.pLang = localStorage.getItem('systemLanguage') || '1000000'
      this.pToken = (userGolfData) ? userGolfData.AccessToken : ''
      this.pHotelId = (userData) ? userData.hotelId : ''
      this.pTimeZone = localStorage.getItem('timeZone')
      this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset')
    },
    async api_RCOM01() {
      const body = {
        KeyGroup : "REPORT_CONFIG",
      }
      await commonServices.getCommon(body).then(response => {
        this.pFrefix = response.Data.find(x => x.KeyCode == "REPORT_FREFIX_OF_KEYGROUP").KeyValue
        this.listFileName = response.Data
      })
    },
    async api_RCF00() {
      await commonServices.api_RCF00().then(response => {
        this.pUrlReport = `${response.Data.find(x => x.SettingCode == "SITE_API_URL").SettingValue}/rp`
        this.pUrlCourse = `${response.Data.find(x => x.SettingCode == "SITE_API_URL").SettingValue}/api/course`
        this.pUrlSite = response.Data.find(x => x.SettingCode == "SITE_API_URL").SettingValue
        this.pUrlService = `${response.Data.find(x => x.SettingCode == "SITE_API_URL").SettingValue}/api/service`
        this.pUrlMember = `${response.Data.find(x => x.SettingCode == "SITE_API_URL").SettingValue}/api/member`
        this.pUrlFolio = response.Data.find(x => x.SettingCode == "FOLIO_API_URL").SettingValue
        this.pUrlAr = `${response.Data.find(x => x.SettingCode == "SITE_API_URL").SettingValue}/api/ar`
      })
    },
    getFileName(result) {
      this.fileName = this.listFileName.find(x => x.KeyCode == result.reportCode + "_REPORT_FILE_NAME").KeyValue
      if (this.fileName) {
        var reportViewer = $("#Ez-report").data("telerik_ReportViewer")
        reportViewer.reportSource({
          report: this.fileName,
          parameters: { ReportCode: result.reportCode, ReportName: result.reportName, pFrefix: this.pFrefix + result.reportCode, pUrlAr: this.pUrlAr, pUrlReport: this.pUrlReport, pUrlCourse:this.pUrlCourse, pUrlSite: this.pUrlSite, pUrlService: this.pUrlService, pUrlMember: this.pUrlMember, pUrlFolio: this.pUrlFolio, pToken: this.pToken, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset, pHotelId: this.pHotelId, pRoleOfCashier: this.pRoleOfCashier },
        })
      }
      this.customStyleTelerik()
    },
    resetReport(ReportCode) {
      if (ReportCode) {
        this.getFileName(ReportCode)
      }
      if (this.fileName) {
        var reportViewer = $("#Ez-report").data("telerik_ReportViewer")
        reportViewer.refreshReport()
      }
    },
    customStyleTelerik() {
      var buttonFooterParam = document.querySelector('.trv-parameters-area-footer button')
      buttonFooterParam.innerHTML = this.$t('golf_common_search')
      buttonFooterParam.classList.add('buttonFooterParam')
    },
    async getRoleCashier() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
        if (res.Status === '200') {
          this.defaultHoleAddBooking = parseInt(res?.Data.find(item => item.KeyCode === 'NUMBER_OF_HOLE_4_BOOKING').KeyValue)
          this.pRoleOfCashier = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_CASHIER').KeyValue)
        }
      })
    },
  },
}