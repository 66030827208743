import { commonServices } from '@/api/common-services'
const permission = JSON.parse(localStorage.getItem('Permission'))
export default {
    data() {
        return {
            listReportGroup: null,
            listReport: [],
            isExtend : true,
            allListReport: null,
            keyWord: null,
            listReportTemp: [],
            reportCodeSelected: null
        }
    },
    created() {
        this.getListReport()
    },
    methods: {
        async getListReport() {
            const body = {
                KeyGroup : "REPORT_GROUP_CONFIG"
            }
            await commonServices.getCommon(body)
            .then(response => {
                this.listReportGroup = response.Data
            })
            this.getListReportDetail()
        },
        async getListReportDetail() {
            for(let i=0;i<this.listReportGroup.length;i++) {
                const body = {
                    KeyGroup: 'REPORT_' + this.listReportGroup[i].KeyCode
                }
                await commonServices.getCommon(body)
                .then(response => {
                    this.listReportTemp.push({ReportGroup:this.listReportGroup[i].KeyCode, ReportGroupName:this.listReportGroup[i].KeyValue ,ReportDetail: response.Data  })
                })
            }
            const permissionReport = []
            this.listReportTemp.forEach(x => {
                const permissionReportDetail = []
                x.ReportDetail.forEach(y => {
                    if(permission.find(item => item === "M"+ y.KeyCode)){
                        permissionReportDetail.push(y)
                    }
                })
                if(permissionReportDetail.length) {
                    permissionReportDetail.sort(function (a, b) { 
                        if(a.KeyCode > b.KeyCode) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                    permissionReport.push({
                        ReportGroupName: x.ReportGroupName,
                        ReportGroup: x.ReportGroup,
                        ReportDetail: permissionReportDetail,
                    })
                }
            })
            this.listReport = permissionReport
            this.allListReport = this.listReport
        },
        resetReport(reportCode, ReportName) {
            this.reportCodeSelected = reportCode
            this.$emit('event', { reportCode: reportCode, reportName: ReportName })
        },
        searchReport() {
            const tmplistReport = []
            this.allListReport.forEach(x => {
        const ReportDetail = []
        x.ReportDetail.forEach((y) => {
          if (y.KeyValue.toUpperCase().includes(this.keyWord.toUpperCase()) || y.KeyCode.toUpperCase().includes(this.keyWord.toUpperCase())) {
            ReportDetail.push(y)
          }
        })

        if (ReportDetail.length) {
            tmplistReport.push({
            ReportGroupName: x.ReportGroupName,
            ReportGroup: x.ReportGroup,
            ReportDetail: ReportDetail,
          })
        }
      })
      this.listReport = tmplistReport
        }
    },
}